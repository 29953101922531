




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconInfo: () => import('@/components/UI/icons/IconInfo.vue'),
  },
})
export default class RepeatabilityDescription extends Vue {
  @Prop({
    required: true,
    type: String,
  }) description!: string
}
