import { render, staticRenderFns } from "./Info.vue?vue&type=template&id=6ec2a9ab&scoped=true&"
import script from "./Info.vue?vue&type=script&lang=ts&"
export * from "./Info.vue?vue&type=script&lang=ts&"
import style0 from "./Info.vue?vue&type=style&index=0&id=6ec2a9ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec2a9ab",
  null
  
)

export default component.exports
import {QItem,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QItem,QIcon})
